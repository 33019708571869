@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');
body {
  font-family: "Montserrat", sans-serif;
}
body.overflow {
  overflow: hidden;
}
.f-light {
  font-weight: 300;
}
.f-medium {
  font-weight: 500;
}
.f-bold {
  font-weight: 700;
}
section {
  padding-top: 50px;
}
@media (min-width:992px) {
  section {
  padding-top: 80px;
}
}.heading--title {
  font-size: 24px;
  color: #333;
  margin-bottom: 40px;
}
@media (min-width:992px) {
  .heading--title {
  font-size: 36px;
  margin-bottom: 50px;
}
}@media (min-width:1349px) {
  .heading--title {
  font-size: 34px;
}
}.divider {
  position: relative;
  width: 100%}
.divider:after {
  position: absolute;
  content: "";
  background-color: #c99632;
}
.divider.large {
  margin-top: 47px;
}
.divider.large:after {
  width: 40px;
  height: 2px;
  left: calc(50% - 20px);
  bottom: -2px;
}
@media (min-width:992px) {
  .divider.large:after {
  width: 80px;
  height: 4px;
  left: calc(50% - 40px);
  margin-top: 69px;
  bottom: -4px;
}
}.divider.light {
  margin-top: 39px;
}
.divider.light:after {
  width: 0;
  height: 0;
}
@media (min-width:992px) {
  .divider.light:after {
  width: 40px;
  height: 2px;
  left: calc(50% - 20px);
  bottom: -2px;
}
}.position-relative {
  position: relative;
}
.slick-slider .slick-prev {
  width: 25px;
  height: 25px;
  left: 15px;
  z-index: 1;
  background: url(http://dev.somosdorika.com/images/dorika-arrows-left.png);
  background-size: contain !important;
  opacity: 1;
}
@media (min-width:992px) {
  .slick-slider .slick-prev {
  width: 40px;
  height: 40px;
}
}.slick-slider .slick-prev:before {
  display: none;
}
.slick-slider .slick-prev:focus, .slick-slider .slick-prev:hover {
  background: url(http://dev.somosdorika.com/images/dorika-arrows-left.png)!important;
  background-size: contain !important;
}
.slick-slider .slick-prev:focus {
  outline: none;
}
.slick-slider .slick-next {
  width: 25px;
  height: 25px;
  right: 15px;
  background: url(http://dev.somosdorika.com/images/dorika-arrows-right.png);
  background-size: contain !important;
  opacity: 1;
}
@media (min-width:992px) {
  .slick-slider .slick-next {
  width: 40px;
  height: 40px;
}
}.slick-slider .slick-next:before {
  display: none;
}
.slick-slider .slick-next:focus, .slick-slider .slick-next:hover {
  background: url(http://dev.somosdorika.com/images/dorika-arrows-right.png)!important;
  background-size: contain !important;
}
.slick-slider .slick-next:focus {
  outline: none;
}
@media (min-width:992px) {
  .mobile {
  display: none;
}
}.desktop {
  display: none;
}
@media (min-width:992px) {
  .desktop {
  display: block;
}
}.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0;
  height: 60px;
  background-color: transparent;
  transition: all .5s;
}
@media (min-width:768px) {
  .navbar {
  height: 80px;
  background-color: transparent;
}
}.navbar.active {
  background-color: #333;
  transition: all .5s;
}
.navbar .row {
  width: calc(100% + 30px);
}
.navbar .navbar-brand {
  color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
  cursor: pointer;
}
.navbar .navbar-brand img {
  width: 96px;
}
@media (min-width:768px) {
  .navbar .navbar-toggler {
  display: none;
}
}
.navbar .navbar-toggler .navbar-toggler-icon {
  height: auto;
  width: auto;
}
.navbar .navbar-collapse {
  position: absolute;
  width: 100vw;
  top: -150px;
  right: 0;
  transition: all .3s;
}
@media (min-width:768px) {
  .navbar .navbar-collapse {
  display: flex;
  position: relative;
  top: 0;
}
}.navbar .navbar-collapse .navbar-nav {
  width: 100%;
  text-align: center;
  padding-top: 8px;
  background-color: #fff;
}
@media (min-width:768px) {
  .navbar .navbar-collapse .navbar-nav {
  background-color: transparent;
  padding-top: 0;
  width: auto;
}
}.navbar .navbar-collapse .navbar-nav .nav-item {
  color: #333;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2a83e;
}
@media (min-width:768px) {
  .navbar .navbar-collapse .navbar-nav .nav-item {
  margin-right: 65px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
  width: auto;
  color: #fff;
}
}.navbar .navbar-collapse .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.navbar .navbar-collapse .navbar-nav .nav-item:after {
  position: absolute;
  content: "";
  width: 0;
  height: 3px;
  background-color: #e2a83e;
  left: 0;
  bottom: -10px;
  transition: all .3s;
}
@media (min-width:768px) {
  .navbar .navbar-collapse .navbar-nav .nav-item.active:after, .navbar .navbar-collapse .navbar-nav .nav-item:hover:after {
  transition: all .3s;
  width: 25px;
}
}.navbar .navbar-collapse.active {
  top: 50px;
}
@media (min-width:768px) {
  .navbar .navbar-collapse.active {
  top: 0;
}
}.footer {
  background-image: linear-gradient(180deg, #333, #1e1e1e);
  padding: 60px 15px 50px;
}
.footer__top--logo {
  width: 175px;
  margin-bottom: 36px;
}
.footer__top--info {
  padding-left: 0;
  margin-bottom: 50px;
}
.footer__top--info li {
  margin-bottom: 10px;
  list-style: none;
  font-size: 18px;
  font-weight: 400;
}
@media (min-width:1348px) {
  .footer__top--info li {
  font-size: 16px;
}
}.footer__top--info li a {
  color: #fff;
  display: flex;
  align-items: center;
}
.footer__top--info li a span {
  display: inline-block;
  margin-right: 16px;
  min-width: 25px;
}
.footer__top--info li a span img {
  display: block;
}
.footer__top--rrss {
  padding-left: 0;
  margin-bottom: 75px;
}
@media (min-width:1367px) {
  .footer__top--rrss {
  margin-bottom: 50px;
}
}.footer__top--rrss li {
  list-style: none;
  margin-right: 40px;
  display: inline-block;
}
.proyectos .project {
  margin-bottom: 75px;
}
@media (min-width:992px) {
  .proyectos .project {
  margin-bottom: 100px;
}
}.proyectos .project--left .tag {
  right: 0;
}
@media (min-width:992px) {
  .proyectos .project--left .tag {
  right: 15px;
}
}.proyectos .project--right .tag {
  left: 0;
}
@media (min-width:992px) {
  .proyectos .project--right .tag {
  left: 15px;
}
}.proyectos .project__text {
  color: #878c96;
}
@media (min-width:992px) {
  .proyectos .project__text {
  padding-top: 32px;
}
}.proyectos .project__text--address {
  font-size: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
@media (min-width:992px) {
  .proyectos .project__text--address {
  margin-bottom: 32px;
}
}@media (min-width:1200px) {
  .proyectos .project__text--address {
  font-size: 14px;
}
}.proyectos .project__text--address span {
  display: inline-block;
}
.proyectos .project__text--address span img {
  display: block;
}
.proyectos .project__text--description {
  font-size: 14px;
}
@media (min-width:992px) {
  .proyectos .project__text--description {
  font-size: 14px;
}
}@media (min-width:1200px) {
  .proyectos .project__text--description {
  font-size: 13px;
}
}@media (min-width:992px) {
  .proyectos .project__text--description p:last-child {
  margin-bottom: 0;
}
}.proyectos .project__text--description strong {
  font-weight: 600;
}
.proyectos .project .col-lg-8 {
  padding-left: 0;
  padding-right: 0;
  height: 300px;
}
@media (min-width:992px) {
  .proyectos .project .col-lg-8 {
  padding-left: 15px;
  padding-right: 15px;
  height: auto;
  max-height: 530px;
}
}.proyectos .project .col-lg-8 .tag {
  background: #c99632;
  color: #fff;
  font-size: 14px;
  padding: 12px 27px;
  display: inline-block;
  font-weight: 500;
  position: absolute;
  top: 0;
  z-index: 1;
}
@media (min-width:992px) {
  .proyectos .project .col-lg-8 .tag {
  font-size: 17px;
  padding: 15px 35px;
}
}@media (min-width:1200px) {
  .proyectos .project .col-lg-8 .tag {
  font-size: 14px;
}
}.proyectos .project .col-lg-8 .slick-slider {
  height: 100%}
.proyectos .project .col-lg-8 .slick-slider .content-slide {
  position: relative;
  overflow: hidden;
  display: flex!important;
}
.proyectos .project .col-lg-8 .slick-slider div {
  height: 100%}
.proyectos .project .col-lg-8 .slick-slider .bg-black {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: -1;
}
.proyectos .project .col-lg-8 .slick-slider .bg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  z-index: -1;
}
.proyectos .project .col-lg-8 .slick-slider img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
@media (min-width:992px) {
  .proyectos-realizados .row {
  padding-right: 15px;
  padding-left: 15px;
}
}.proyectos-realizados .row .item {
  height: 200px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
@media (min-width:992px) {
  .proyectos-realizados .row .item {
  height: 365px;
}
}.proyectos-realizados .row .item__image {
  object-fit: cover;
  object-position: center;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.proyectos-realizados .row .item__image:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.proyectos-realizados .row .item__name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  background-color: hsla(0, 0%, 100%, .95);
  border: 2px solid #c99632;
  border-left: 0;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: auto;
  padding: 10px 20px 10px 12px;
}
@media (min-width:992px) {
  .proyectos-realizados .row .item__name {
  padding: 14px 25px 15px 12px;
  font-size: 21px;
  font-weight: 400;
  bottom: 30px;
}
}.proyectos-realizados .row .popup {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  padding-top: 25px;
  display: none;
}
.proyectos-realizados .row .popup.active {
  position: fixed;
  z-index: 1050;
  width: 100%;
  height: 100%;
  display: flex;
}
.proyectos-realizados .row .popup .container {
  margin-top: auto;
  margin-bottom: auto;
}
.proyectos-realizados .row .popup__content {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  padding: 17px 0 15px;
  max-height: 96vh;
  margin-right: 0;
  margin-left: 0;
  overflow: auto;
}
@media (min-width:992px) {
  .proyectos-realizados .row .popup__content {
  max-height: 93vh;
  margin-right: 80px;
  margin-left: 80px;
  border-radius: 4px;
  overflow: hidden;
}
}.proyectos-realizados .row .popup__content--gallery {
  padding-top: 33px;
  height: 300px;
}
@media (min-width:992px) {
  .proyectos-realizados .row .popup__content--gallery {
  padding-top: 0;
  height: 100%}
}.proyectos-realizados .row .popup__content--gallery .slick-slider, .proyectos-realizados .row .popup__content--gallery .slick-slider div {
  height: 100%}
.proyectos-realizados .row .popup__content--gallery .slick-slider .content-slide {
  position: relative;
  overflow: hidden;
  display: flex!important;
  background-color: #000;
}
.proyectos-realizados .row .popup__content--gallery .slick-slider .content-slide img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
.proyectos-realizados .row .popup__content--gallery .slick-dots li {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: auto;
  height: auto;
  border: 3px solid transparent;
  margin: 0 1px;
}
.proyectos-realizados .row .popup__content--gallery .slick-dots li:first-child, .proyectos-realizados .row .popup__content--gallery .slick-dots li:last-child {
  margin-left: 0;
}
.proyectos-realizados .row .popup__content--gallery .slick-dots li.slick-active {
  border-color: #c99632;
}
.proyectos-realizados .row .popup__content--gallery .slick-dots li img {
  max-width: 100%}
.proyectos-realizados .row .popup__content--text {
  color: #878c96;
  font-weight: 300;
}
@media (min-width:992px) {
  .proyectos-realizados .row .popup__content--text {
  overflow: auto;
  max-height: 85vh;
}
}.proyectos-realizados .row .popup__content--text h4 {
  font-size: 22px;
  font-weight: 400;
  color: #333;
}
.proyectos-realizados .row .popup__content--text .divider.light {
  margin-top: 22px;
}
.proyectos-realizados .row .popup__content--text .divider.light:after {
  width: 20px;
  height: 2px;
  left: 0;
}
@media (min-width:992px) {
  .proyectos-realizados .row .popup__content--text .divider.light {
  margin-top: 18px;
}
}.proyectos-realizados .row .popup__content--text .divider.mobile:after {
  width: 20px;
  height: 2px;
}
.proyectos-realizados .row .popup__content--text .description {
  font-size: 12px;
  padding-top: 22px;
}
.proyectos-realizados .row .popup__content--text .description ul {
  padding-left: 15px;
}
.proyectos-realizados .row .popup__content--text .description strong {
  font-weight: 600;
}
.proyectos-realizados .row .popup__content--text .details {
  font-size: 12px;
  padding-left: 0;
}
.proyectos-realizados .row .popup__content--text .details li {
  list-style: none;
}
.proyectos-realizados .row .popup__content--text .details li strong {
  font-weight: 600;
}
.proyectos-realizados .row .popup__content .button-close {
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: -3px;
}
.proyectos-realizados .row .popup__content .button-close img {
  display: flex;
  margin-left: auto;
}
.form {
  margin-bottom: 100px;
}
.form .form-group {
  color: #333;
  font-size: 16px;
  margin-bottom: 30px;
}
@media (min-width:992px) {
  .form .form-group {
  font-size: 18px;
  margin-bottom: 42px;
}
}@media (min-width:1200px) {
  .form .form-group {
  font-size: 18px;
}
}@media (min-width:1348px) {
  .form .form-group {
  font-size: 16px;
}
}.form .form-group .label {
  margin-bottom: 0;
}
.form .form-group .form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #e2a83e;
}
.form .form-group .form-control:focus {
  box-shadow: none!important;
}
.form .form-group .btn-primary {
  background-color: #333;
  padding: 15px 50px;
  border: 0;
  font-size: 14px;
}
.form .form-group .btn-primary:hover {
  background-color: #333;
  opacity: .9;
}
.form .form-group .btn-primary:focus {
  box-shadow: none!important;
}
.form .form-group .btn-primary:active {
  box-shadow: none!important;
  background-color: #333;
  opacity: .9;
}
.form .form-group .btn-primary .spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 1px;
  margin-right: 10px;
  margin-bottom: 1px;
}
header {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
header .banner-image {
  display: block;
  object-fit: cover;
  object-position: center;
  height: 100vh;
  margin-left: -140%}
@media (min-width:420px) {
  header .banner-image {
  margin-left: -90%}
}@media (min-width:576px) {
  header .banner-image {
  margin-left: -30%}
}@media (min-width:800px) {
  header .banner-image {
  margin-left: 0;
}
}@media (min-width:1348px) {
  header .banner-image {
  max-width: 100%;
  height: auto;
}
}@media (min-width:1367px) {
  header .banner-image {
  width: 100%}
}header .banner-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(51, 51, 51, .6), rgba(51, 51, 51, 0));
}
header .banner-text h1 {
  font-size: 40px;
  color: #fff;
  padding-top: 130px;
}
@media (min-width:800px) {
  header .banner-text h1 {
  padding-top: 100px;
}
}@media (min-width:992px) {
  header .banner-text h1 {
  font-size: 68px;
  padding-top: 75px;
}
}@media (min-width:1200px) {
  header .banner-text h1 {
  font-size: 82px;
}
}header .banner-text h1 span {
  display: inline-block;
}
.nosotros .heading--title {
  margin-bottom: 20px;
}
@media (min-width:992px) {
  .nosotros .heading--title {
  margin-bottom: 30px;
}
}.nosotros .heading--subtitle {
  font-size: 14px;
  line-height: 25px;
  color: #878c96;
}
@media (min-width:992px) {
  .nosotros .heading--subtitle {
  font-size: 16px;
  line-height: 27px;
}
}.nosotros .heading--subtitle strong {
  font-weight: 600;
}
.contacto .heading--title {
  margin-bottom: 20px;
}
@media (min-width:992px) {
  .contacto .heading--title {
  margin-bottom: 30px;
}
}.contacto .heading--subtitle {
  color: #878c96;
  font-size: 14px;
}
@media (min-width:992px) {
  .contacto .heading--subtitle {
  font-size: 18px;
  margin-bottom: 75px;
}
}@media (min-width:1200px) {
  .contacto .heading--subtitle {
  font-size: 16px;
}
}.contacto__map {
  overflow: hidden;
}
.contacto__map img {
  width: 330%;
  margin-left: -115%;
  filter: grayscale(100);
}
@media (min-width:768px) {
  .contacto__map img {
  width: 180%;
  margin-left: -41%}
}@media (min-width:992px) {
  .contacto__map img {
  width: 100%;
  margin-left: 0;
}
}